/**
Right now we are not disabling the preflight of Tailwind. If needed, this is possible by implementing the following - https://tailwindcss.com/docs/preflight#disabling-preflight
 */
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=JetBrains+Mono:ital,wght@0,100..800;1,100..800&display=swap');
@import 'tailwindcss';

@plugin "tailwindcss-motion";
@plugin "daisyui" {
    exclude: scrollbar;
    themes: smooved --default;
}

@theme {
    /** Typography */
    --font-sans: Inter, system-ui, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    --font-mono: 'JetBrains Mono', ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;

    --font-weight-*: initial; /* Makes sure no default tailwind font-weights are available */
    --font-weight-default: 350;
    --font-weight-emphasis: 450;

    --text-*: initial;

    /* This setup makes sure that title-xl includes the correct line-height and font-weight by default */
    --text-title-xl: 1.75rem;
    --text-title-xl--line-height: 1.75rem;
    --text-title-xl--font-weight: var(--font-weight-default);

    --text-title-lg: 1.25rem;
    --text-title-lg--line-height: 1.75rem;
    --text-title-lg--font-weight: var(--font-weight-emphasis);

    --text-title-md: 1rem;
    --text-title-md--line-height: 1.375rem;
    --text-title-md--font-weight: var(--font-weight-emphasis);

    --text-title-sm: 0.875rem;
    --text-title-sm--line-height: 1.25rem;
    --text-title-sm--font-weight: var(--font-weight-emphasis);

    --text-body-md: 1rem;
    --text-body-md--line-height: 1.375rem;
    --text-body-md--font-weight: var(--font-weight-default);

    --text-body-sm: 0.875rem;
    --text-body-sm--line-height: 1.25rem;
    --text-body-sm--font-weight: var(--font-weight-default);

    --text-ui-md: 1rem;
    --text-ui-md--line-height: 1.375rem;
    --text-ui-md--font-weight: var(--font-weight-emphasis);

    --text-ui-sm: 0.875rem;
    --text-ui-sm--line-height: 1.25rem;
    --text-ui-sm--font-weight: var(--font-weight-emphasis);

    --text-data-md: 1rem;
    --text-data-md--line-height: 1.375rem;
    --text-data-md--font-weight: var(--font-weight-default);

    --text-data-sm: 0.875rem;
    --text-data-sm--line-height: 1.25rem;
    --text-data-sm--font-weight: var(--font-weight-default);

    /** Spacing */
    --spacing: 0.25rem;

    /** Radius */
    --radius-*: initial;
    --radius-md: 0.375rem;
    --radius-xl: 0.75rem;

    /** Blur */
    --blur-*: initial;
    --blur-md: 12px;

    /** Primitive Colors */
    --color-*: initial;
    --color-white: rgba(255, 255, 255, 1);

    --color-neutral-900: rgba(14, 21, 22, 1);
    --color-neutral-800: rgba(33, 47, 49, 1);
    --color-neutral-500: rgba(87, 106, 103, 1);
    --color-neutral-300: rgba(197, 208, 206, 1);
    --color-neutral-200: rgba(230, 235, 235, 1);
    --color-neutral-100: rgba(242, 245, 245, 1);
    --color-neutral-50: rgba(250, 252, 252, 1);

    --color-green-900: rgba(3, 29, 32, 1);
    --color-green-800: rgba(22, 54, 60, 1);
    --color-green-500: rgba(59, 134, 123, 1);
    --color-green-300: rgba(184, 221, 216, 1);
    --color-green-200: rgba(225, 240, 240, 1);
    --color-green-100: rgba(240, 247, 247, 1);
    --color-green-50: rgba(250, 252, 252, 1);

    --color-purple-500: rgba(215, 197, 222, 1);

    --color-red-500: rgba(233, 96, 69, 1);
    --color-red-200: rgba(255, 230, 224, 1);
    --color-red-100: rgba(255, 242, 240, 1);
    --color-red-50: rgba(255, 251, 250, 1);

    --color-yellow-700: rgba(200, 160, 59, 1);
    --color-yellow-500: rgba(251, 211, 109, 1);

    --color-white-alpha-90: rgba(255, 255, 255, 0.9);
    --color-white-alpha-70: rgba(255, 255, 255, 0.7);
    --color-white-alpha-50: rgba(255, 255, 255, 0.5);
    --color-white-alpha-20: rgba(255, 255, 255, 0.2);

    --color-green-alpha-90: rgba(240, 247, 247, 0.9);
    --color-green-alpha-70: rgba(240, 247, 247, 0.9);

    /* Semantic Colors */
    --color-status-success: var(--color-green-500);
    --color-status-error: var(--color-red-500);

    --color-bg-primary: var(--color-green-100);
    --color-bg-secondary: var(--color-neutral-50);

    --color-bg-btn-primary: var(--color-green-800);
    --color-bg-btn-primary-hover: var(--color-green-900);
    --color-bg-btn-primary-disabled: var(--color-neutral-300);
    --color-bg-btn-secondary: var(--color-neutral-50);
    --color-bg-btn-secondary-hover: var(--color-neutral-100);
    --color-bg-btn-secondary-disabled: var(--color-neutral-50);

    --color-bg-card-default: var(--color-white-alpha-90);
    --color-bg-card-brand: var(--color-green-500);
    --color-bg-card-error: var(--color-red-200);

    --color-bg-input-default: var(--color-white);
    --color-bg-input-default-hover: var(--color-neutral-50);
    --color-bg-input-default-pressed: var(--color-neutral-100);
    --color-bg-input-error: var(--color-white);
    --color-bg-input-error-hover: var(--color-red-50);
    --color-bg-input-error-pressed: var(--color-red-100);

    --color-text-primary: var(--color-neutral-800);
    --color-text-secondary: var(--color-neutral-500);
    --color-text-tertiary: var(--color-neutral-300);
    --color-text-inverse: var(--color-white);
    --color-text-inverse-secondary: var(--color-green-200);
    --color-text-error: var(--color-status-error);

    --color-icon-primary: var(--color-neutral-800);
    --color-icon-secondary: var(--color-neutral-500);
    --color-icon-tertiary: var(--color-neutral-300);
    --color-icon-error: var(--color-status-error);
    --color-icon-success: var(--color-status-success);

    --color-stroke-card: var(--color-white-alpha-20);
    --color-stroke-input: var(--color-neutral-100);
    --color-stroke-input-focus: var(--color-neutral-300);
    --color-stroke-input-error: var(--color-status-error);

    /** Animation Durations */
    --motion-duration-modal-out: 200ms;
}

/* Import the daisyui theme */
@import './daisy-ui.css';
